import $ from "jquery";
import _ from "lodash";
import ModalLib from "../lib/ModalLib";

export default class Modal {
  constructor() {
    this.modal = document.getElementById("modal");
    this.lockFlg = false;

    // lib
    this.modalLib = new ModalLib();

    this.init();
  }

  init() {
    if (!this.modal) return false;

    this.bindEvents();
  }

  bindEvents() {
    // タレント検索 詳細モーダル
    $(document).on('click', '.js-search-modal-link', e => {
      e.preventDefault();
      const elm = e.currentTarget;
      const modal = elm.getAttribute("data-modal");
      const modalElm = document.getElementById(modal);
      if (this.lockFlg) return false;
      this.lockFlg = true;

      if (modalElm) {
        this.setSearchModalContent(elm, modalElm).done(() => {
          const modalContent = modalElm.getElementsByClassName("modal__content")[0];
          if (modalContent) {
            _.delay(() => {
              modalContent.scrollTo(0, 0);
            }, 10);
          }

          this.modalLib.openModal(modalElm).done(() => {
            this.lockFlg = false;
          }).fail(() => {
            this.lockFlg = false;
          })
        }).fail(() => {
          this.lockFlg = false;
        });
      } else {
        this.lockFlg = false;
      }
    });

    // 出演履歴検索一覧内の通常リンク
    $(document).on('click', '.js-search-appearance-default-link', e => {
      e.preventDefault();
      e.stopPropagation();
      const elm = e.currentTarget;
      const href = elm.href;
      window.open(href, '_blank');
    });

    // 出演履歴検索 詳細モーダル
    $(document).on('click', '.js-search-appearance-modal-link', e => {
      e.preventDefault();
      const elm = e.currentTarget;
      const modal = elm.getAttribute("data-modal");
      const modalElm = document.getElementById(modal);
      if (this.lockFlg) return false;
      this.lockFlg = true;

      if (modalElm) {
        this.setSearchAppearanceModalContent(elm, modalElm).done(() => {
          const modalContent = modalElm.getElementsByClassName("modal__content")[0];
          if (modalContent) {
            _.delay(() => {
              modalContent.scrollTo(0, 0);
            }, 10);
          }

          this.modalLib.openModal(modalElm).done(() => {
            this.lockFlg = false;
          }).fail(() => {
            this.lockFlg = false;
          })
        }).fail(() => {
          this.lockFlg = false;
        });
      } else {
        this.lockFlg = false;
      }
    });

    // タレント選択モーダル
    $(document).on('click', '.js-list-check-modal-link', e => {
      e.preventDefault();
      const elm = e.currentTarget;
      const modal = elm.getAttribute("data-modal");
      const modalElm = document.getElementById(modal);
      if (this.lockFlg) return false;
      this.lockFlg = true;

      if (modalElm) {
        const modalContent = modalElm.getElementsByClassName("modal__content")[0];
        if (modalContent) {
          _.delay(() => {
            modalContent.scrollTo(0, 0);
          }, 10);
        }

        this.modalLib.openModal(modalElm).done(() => {
          this.lockFlg = false;
        }).fail(() => {
          this.lockFlg = false;
        })
      } else {
        this.lockFlg = false;
      }
    });

    $(document).on('click', '.js-modal-close', e => {
      e.preventDefault();
      if (this.lockFlg) return false;
      this.lockFlg = true;

      this.modalLib.closeModal().done(() => {
        this.lockFlg = false;
      }).fail(() => {
        this.lockFlg = false;
      });
    });
  }

  /**
   * 検索結果モーダル内HTML生成
   * @param {element} elm - ボタン要素
   * @param {element} modalElm - 表示するモーダル
   * @return {undefined}
   */
  setSearchModalContent(elm, modalElm) {
    const d = $.Deferred();

    const id = elm.getAttribute("data-id");
    const inner = modalElm.getElementsByClassName("js-modal-content-inner")[0];
    if (id && inner) {
      const token = $('input[name="_csrfToken"]').val();
      const url = '/search/view/' + id;
      $.ajax({
        type: 'post',
        url: url,
        headers: { 'X-CSRF-Token' : token },
      })
      .done(data => {
        inner.innerHTML = data;
        d.resolve();
      })
      .fail(() => {
        d.reject();
      });
    } else {
      d.reject();
    }

    return d.promise();
  }

  /**
   * 出演履歴 検索結果モーダル内HTML生成
   * @param {element} elm - ボタン要素
   * @param {element} modalElm - 表示するモーダル
   * @return {undefined}
   */
  setSearchAppearanceModalContent(elm, modalElm) {
    const d = $.Deferred();

    const id = elm.getAttribute("data-id");
    const inner = modalElm.getElementsByClassName("js-modal-content-inner")[0];
    if (id && inner) {
      const token = $('input[name="_csrfToken"]').val();
      const url = '/appearance/view/' + id;
      $.ajax({
        type: 'post',
        url: url,
        headers: { 'X-CSRF-Token' : token },
      })
      .done(data => {
        inner.innerHTML = data;
        d.resolve();
      })
      .fail(() => {
        d.reject();
      });
    } else {
      d.reject();
    }

    return d.promise();
  }
}
