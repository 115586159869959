import $ from "jquery";
import _ from "lodash";

export default class SearchListCheck {
  constructor() {
    this.list = [];
    this.preText = "";
    this.lockFlg = false;
    this.init();
  }

  init() {
    this.bindEvents();
  }

  /**
   * イベント設定
   * @return {undefined}
   */
  bindEvents() {
    // チェックボタン
    $(document).on("change", ".js-list-check", e => {
      const inputElm = e.currentTarget;
      const id = inputElm.getAttribute("data-id");
      if (id) {
        if (inputElm.checked) {
          this.addList(id);
        } else {
          this.removeList(id);
        }
        this.setFixedText();
        this.setModalText();
      }
    });

    // コピーボタン
    $(document).on("click", ".js-list-check-modal-copy-btn", e => {
      if (this.lockFlg) return false;
      this.lockFlg = true;
      this.copy();
      const elm = e.currentTarget;
      elm.innerText = "コピー完了";
      _.delay(() => {
        elm.innerText = "コピーする";
        this.lockFlg = false;
      }, 1000);
    });

    // タレント詳細モーダル
    $(document).on('click', '.js-search-modal-link', e => {
      const elm = e.currentTarget;
      const checkbox = document.getElementsByClassName("js-search-modal-checkbox")[0];
      if (checkbox) {
        // チェックボックスにIDを設定
        const id = elm.getAttribute("data-id");
        if (id) {
          checkbox.setAttribute("data-id", id);

          // チェックボックの状態を設定
          if (_.filter(this.list, {id: id}).length) {
            checkbox.checked = true;
            checkbox.parentNode.classList.add("-check");
          } else {
            checkbox.checked = false;
            checkbox.parentNode.classList.remove("-check");
          }
        }
      }
    });

    // タレント詳細モーダル内のチェックボックス
    $(document).on("change", ".js-search-modal-checkbox", e => {
      const elm = e.currentTarget;
      const id = elm.getAttribute("data-id");
      if (id) {
        if (elm.checked) {
          elm.parentNode.classList.add("-check");
        } else {
          elm.parentNode.classList.remove("-check");
        }
        const listElms = document.getElementsByClassName("js-list-check");
        if (listElms.length) {
          Array.prototype.forEach.call(listElms, listElm => {
            const elmId = listElm.getAttribute("data-id");
            if (id == elmId) {
              listElm.click();
            }
          });
        }
      }
    });
  }

  // クリップボードにコピー
  copy() {
    var area = document.createElement("textarea");
    area.textContent = this.preText;
    document.body.appendChild(area);
    area.select();
    document.execCommand("copy");
    document.body.removeChild(area);
  }

  // リストに追加
  addList(id) {
    const listElms = document.getElementsByClassName("js-list-check-text");
    if (listElms.length) {
      Array.prototype.forEach.call(listElms, elm => {
        if (id == elm.getAttribute("data-id")) {
          const obj = {
            id: id,
            name: elm.getAttribute("data-name"),
            fname: elm.getAttribute("data-fname"),
            bday: elm.getAttribute("data-bday"),
            age: elm.getAttribute("data-age"),
            gender: elm.getAttribute("data-gender"),
            link: elm.getAttribute("data-link"),
            detail: elm.getAttribute("data-detail"),
            pdf: elm.getAttribute("data-pdf"),
          };
          this.list.push(obj);
        }
      });
    }
  }

  // リストから削除
  removeList(id) {
    const newList = this.list.filter(x => {
      return x.id != id;
    });
    this.list = newList;
  }

  setFixedText() {
    const fixedElm = document.getElementsByClassName("js-fixed-selected-list")[0];
    const textElm = document.getElementsByClassName("js-fixed-selected-list-text")[0];

    if (fixedElm) {
      if (this.list.length) {
        fixedElm.classList.add("-show");
      } else {
        fixedElm.classList.remove("-show");
      }
    }

    if (textElm) {
      if (this.list.length) {
        let newListArr = [];
        _.each(this.list, list => {
          newListArr.push(list.name);
        });
        const newText = this.list.length + "人 選択中: " + newListArr.join("、");
        textElm.innerText = newText;
      } else {
        textElm.innerText = "";
      }
    }
  }

  // モーダルテキスト更新
  setModalText() {
    const headingElm = document.getElementsByClassName("js-list-check-modal-heading")[0];
    const innerElm = document.getElementsByClassName("js-list-check-modal-text")[0];

    if (headingElm) {
      if (this.list.length) {
        headingElm.innerText = this.list.length + "名をリストアップしました。";
      } else {
        headingElm.innerText = "";
      }
    }

    if (innerElm) {
      if (this.list.length) {
        let preNo = 1;
        this.preText = "";
        _.each(this.list, list => {
          if (list.name) {
            this.preText += "No." + preNo + "\n";

            this.preText += list.name
            if (list.fname) this.preText += " " + list.fname;
            this.preText += "\n";

            if (list.bday) this.preText += list.bday;
            if (list.age) this.preText += " (" + list.age + ")";
            if (list.gender) this.preText += " " + list.gender;
            this.preText += "\n";

            this.preText += list.link + "\n";
            this.preText += list.detail + "\n";
            this.preText += list.pdf + "\n";

            preNo++;
          }
        });
        innerElm.innerHTML = "<pre>" + this.preText + "</pre>";
      } else {
        innerElm.innerHTML = "";
      }
    }
  }
}
