import $ from "jquery";
import _ from "lodash";

export default class ModalLib {
  constructor() {
    this.body = document.getElementsByTagName("body")[0];
    this.modal = document.getElementById("modal");
  }

  openModal(modalElm) {
    const d = $.Deferred();

    $(this.modal).off("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend");
    if (modalElm) {
      this.body.classList.add("-modalOpen");
      modalElm.classList.add("-show");
      this.modal.classList.add("-visible");

      if (!this.modal.classList.contains("-show")) {
        d.reject();
      }

      $(this.modal).on("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend", () => {
        $(this.modal).off("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend");
        d.resolve();
      });

      _.delay(() => {
        this.modal.classList.add("-show");
      }, 100);
    } else {
      d.reject();
    }

    return d.promise();
  }

  closeModal() {
    const d = $.Deferred();

    if (!this.modal.classList.contains("-show")) {
      d.reject();
    }

    this.modal.classList.remove("-show");
    $(this.modal).on("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend", () => {
      this.body.classList.remove("-modalOpen");
      this.modal.classList.remove("-visible");

      const innerElm = this.modal.getElementsByClassName("modal__inner");
      Array.prototype.forEach.call(innerElm, elm => {
        elm.classList.remove("-show");
      });

      $(this.modal).off("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend");
      d.resolve();
    });

    return d.promise();
  }
}
