import $ from "jquery";
import Form from './Form';
import FormAddTalent from './FormAddTalent';
import Modal from "./Modal";
import Search from "./Search";
import ImageList from "./ImageList";
import SearchListCheck from "./SearchListCheck";

$(() => {
  new Form();

  // 出演履歴 タレント追加
  const addTalentElms = document.getElementsByClassName("js-add-talent");
  if (addTalentElms.length) {
    Array.prototype.forEach.call(addTalentElms, elm => {
      new FormAddTalent(elm);
    });
  }

  // Modal
  new Modal();

  // 検索フォーム
  if (
    document.getElementById("page-top") ||
    document.getElementById("page-appearance")
  ) {
    new Search();
  }

  // ImageList Viewページ画像切り替え
  new ImageList();

  // タレント検索 リストチェック処理
  if (document.getElementsByClassName("js-list-check").length) {
    new SearchListCheck();
  }
});
