import $ from "jquery";
import JSZip from "jszip";

export default class ImageList {
  constructor() {
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    // 画像切り替え
    $(document).on('click', '.js-image-list-thumb', e => {
      e.preventDefault();
      const elm = e.currentTarget;
      const container = elm.closest(".js-image-list");
      const imgElm = elm.getElementsByTagName("img")[0];

      $(".js-image-list-thumb").removeClass("-active");
      elm.classList.add("-active");

      if (imgElm && container) {
        const mainElm = container.getElementsByClassName("js-image-list-main")[0];
        if (mainElm) {
          const mainImgElm = mainElm.getElementsByTagName("img")[0];
          if (mainImgElm) {
            mainImgElm.src = imgElm.src;
          }
        }
      }
    });

    // ダウンロードボタン
    $(document).on('click', '.js-image-list-download', e => {
      let sources = [].slice.call(
        document.querySelectorAll(".js-image-list-thumb img")
      ).map(img => img.getAttribute("src"));

      if (sources.length == 0) {
        sources = [].slice.call(
          document.querySelectorAll(".js-image-list-main img")
        ).map(img => img.getAttribute("src"));
      }

      if (sources.length == 0) {
        return false;
      }

      const id = e.currentTarget.getAttribute("data-id");
      const type = e.currentTarget.getAttribute("data-type");

      Promise.all(
        sources.map(url => {
          return this.getImage(url);
        })
      ).then(blobs => {
        this.generateZip(blobs, id, type);
      });
    });
  }

  // urlの画像をダウンロードしてBlobを返却
  getImage(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = "blob";
      xhr.onload = e => {
        const file_type = url.split('.').pop();
        resolve({data: e.currentTarget.response, type: file_type});
      };
      xhr.onerror = () => resolve({ data: null });
      xhr.onabort = () => resolve({ data: null });
      xhr.ontimeout = () => resolve({ data: null });
      xhr.send();
    });
  }

  // zipにまとめてダウンロード
  generateZip(images, id, type) {
    let zip = new JSZip();
    // フォルダ名
    let folderName = "carte_" + id + "_images";
    if (type != "person") {
      folderName = "appearance_" + id + "_images";
    }
    let folder = zip.folder(folderName);

    // フォルダ下に画像を格納
    let image_num = 1;
    images.forEach(image => {
      if (image.data) {
        let fileName = "carte_" + id + "_" + ('000' + image_num).slice(-3) + "." + image.type;
        if (type != "person") {
          fileName = "appearance_" + id + "_" + ('000' + image_num).slice(-3) + "." + image.type;
        }
        folder.file(fileName, image.data);
        image_num++;
      }
    });

    // zipを生成
    zip.generateAsync({ type: "blob" }).then(blob => {
      // ダウンロードリンクを 生成
      let dlLink = document.createElement("a");

      // blob から URL を生成
      const dataUrl = URL.createObjectURL(blob);
      dlLink.href = dataUrl;
      dlLink.download = `${folderName}.zip`;

      // 設置/クリック/削除
      document.body.insertAdjacentElement("beforeEnd", dlLink);
      dlLink.click();
      dlLink.remove();

      // オブジェクト URL の開放
      setTimeout(function() {
        window.URL.revokeObjectURL(dataUrl);
      }, 1000);
    });
  }
}
