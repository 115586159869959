import $ from "jquery";

// タレント検索フォーム
// 出演履歴検索フォーム
export default class Search {
  constructor() {
    this.lockFlg = false;
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const searchFormToggleBtn = document.getElementsByClassName("js-search-form-toggle")[0];
    if (searchFormToggleBtn) {
      searchFormToggleBtn.addEventListener("click", e => {
        e.preventDefault();
        if (this.lockFlg) return false;
        this.lockFlg = true;

        if (searchFormToggleBtn.classList.contains("-active")) {
          this.changeSearchFormClose();
        } else {
          this.changeSearchFormOpen();
        }
      });
      // 初期開閉設定
      if (searchFormToggleBtn.classList.contains("-active")) {
        this.changeSearchFormOpen(0);
      } else {
        this.changeSearchFormClose(0);
      }
    }

    // 表示切り替え
    const searchChangeViewElms = document.getElementsByClassName("js-search-change-view");
    if (searchChangeViewElms.length) {
      const listElm = document.getElementById("list");
      if (listElm) {
        Array.prototype.forEach.call(searchChangeViewElms, elm => {
          elm.addEventListener("click", e => {
            e.preventDefault();
            const type = elm.getAttribute("data-type");
            if (type == "1") {
              listElm.classList.add("list--type-box");
            } else if (type == "2") {
              listElm.classList.remove("list--type-box");
            }
            Array.prototype.forEach.call(searchChangeViewElms, elm => {
              if (e.currentTarget == elm) {
                elm.classList.add("-active");
              } else {
                elm.classList.remove("-active");
              }
            });
          });
        });
      }
    }
  }

  changeSearchFormOpen(speed = 400) {
    const btn = document.getElementsByClassName("js-search-form-toggle")[0];
    const nextElm = btn.nextElementSibling;
    if (btn) {
      btn.classList.add("-active");
    }
    if (nextElm) {
      $(nextElm).slideDown(speed, () => {
        this.lockFlg = false;
      });
    }
  }

  changeSearchFormClose(speed = 400) {
    const btn = document.getElementsByClassName("js-search-form-toggle")[0];
    const nextElm = btn.nextElementSibling;
    if (btn) {
      btn.classList.remove("-active");
    }
    if (nextElm) {
      $(nextElm).slideUp(speed, () => {
        this.lockFlg = false;
      });
    }
  }
}
