import $ from "jquery";
import _ from "lodash";
import ModalLib from "../lib/ModalLib";

// 出演履歴 タレント追加モーダル処理
export default class FormAddTalent {
  constructor(container) {
    this.modal = document.getElementById("modal");
    this.sort = 1;
    this.container = container;
    this.lockFlg = false;

    // lib
    this.modalLib = new ModalLib();

    this.init();
  }

  init() {
    if (!this.modal) return false;

    this.sort = this.container.getAttribute("data-start-num") || 1;

    this.bindEvents();
  }

  /**
   * イベント設定
   * @return {undefined}
   */
  bindEvents() {
    const addBtn = this.container.getElementsByClassName("js-add-talent-btn")[0];
    const searchBtn = this.modal.getElementsByClassName("js-add-talent-modal-search")[0];
    const submitBtn = this.modal.getElementsByClassName("js-add-talent-modal-submit")[0];

    // タレント追加ボタン
    if (addBtn) {
      addBtn.addEventListener("click", e => {
        e.preventDefault();
        const modalElm = document.getElementById("addTalentModal");
        if (this.lockFlg) return false;
        this.lockFlg = true;

        if (modalElm) {
          this.modalLib.openModal(modalElm).done(() => {
            this.lockFlg = false;
          }).fail(() => {
            this.lockFlg = false;
          })
        } else {
          this.lockFlg = false;
        }
      });
    }

    // 検索ボタン
    if (searchBtn) {
      searchBtn.addEventListener("click", e => {
        e.preventDefault();
        if (this.lockFlg) return false;
        this.lockFlg = true;

        const keywordElm = this.modal.getElementsByClassName("js-add-talent-modal-keyword")[0];
        if (keywordElm) {
          if (keywordElm.value) {
            this.searchTalent(keywordElm.value).done(() => {
              this.lockFlg = false;
            }).fail(() => {
              this.lockFlg = false;
            });
          } else {
            this.lockFlg = false;
          }
        } else {
          this.lockFlg = false;
        }
      });
    }

    // 完了ボタン
    if (submitBtn) {
      submitBtn.addEventListener("click", e => {
        e.preventDefault();
        if (this.lockFlg) return false;
        this.lockFlg = true;

        let id = "";
        let name = "";

        const selectElm = this.modal.getElementsByClassName("js-add-talent-modal-select")[0];
        if (selectElm) {
          const optionElms = selectElm.getElementsByTagName("option");
          if (optionElms.length) {
            Array.prototype.forEach.call(optionElms, elm => {
              if (elm.selected) {
                id = elm.value;
                name = elm.innerText;
              }
            });
          }
        }

        if (id && name) {
          this.createTalentBlock(id, name).done(() => {
            this.modalLib.closeModal().done(() => {
              this.clearModal();
              this.lockFlg = false;
            }).fail(() => {
              this.lockFlg = false;
            });
          }).fail(() => {
            this.lockFlg = false;
          });
        } else {
          this.lockFlg = false;
        }
      });
    }

    // タレント削除ボタン
    $(document).on("click", ".js-add-talent-delete-parent", e => {
      e.preventDefault();
      const parentElm = e.currentTarget.closest(".js-add-talent-parent");
      if (parentElm) {
        parentElm.parentNode.removeChild(parentElm);
      }
    })
  }

  /**
   * タレント検索処理
   * @return {promise}
   */
  searchTalent(freeword) {
    const d = $.Deferred();
    const token = $('input[name="_csrfToken"]').val();

    var sendData = {
      'freeword': freeword,
    };

    $.ajax({
        type: 'post',
        url: '/histories/search',
        data: sendData,
        headers: { 'X-CSRF-Token' : token },
    })
    .done(data => {
      this.setSelectOptions(data);
      d.resolve();
    })
    .fail(() => {
      d.reject();
    });

    return d.promise();
  }

  /**
   * タレント検索モーダル初期化
   * @return {promise}
   */
  clearModal() {
    const keywordElm = this.modal.getElementsByClassName("js-add-talent-modal-keyword")[0];
    const selectElm = this.modal.getElementsByClassName("js-add-talent-modal-select")[0];
    const submitElm = this.modal.getElementsByClassName("js-add-talent-modal-submit")[0];
    if (keywordElm) {
      keywordElm.value = "";
    }
    if (selectElm) {
      selectElm.innerHTML = "";
      selectElm.classList.remove("-show");
    }
    if (submitElm) {
      submitElm.classList.remove("-show");
    }
  }

  /**
   * タレント検索 option要素追加
   * @return {promise}
   */
  setSelectOptions(data) {
    const selectElm = this.modal.getElementsByClassName("js-add-talent-modal-select")[0];
    const submitElm = this.modal.getElementsByClassName("js-add-talent-modal-submit")[0];
    if (selectElm) {
      selectElm.innerHTML = "";
      if (Object.keys(data).length > 0) {
        const fragment = document.createDocumentFragment();
        const optionElm = document.createElement("option");
        _.each(data, (item, id) => {
          const optionElm = document.createElement("option");
          optionElm.value = id;
          optionElm.innerText = item;
          fragment.appendChild(optionElm);
        });
        selectElm.appendChild(fragment);
        selectElm.classList.add("-show");
        if (submitElm) {
          submitElm.classList.add("-show");
        }
      }
    }
  }

  /**
   * 出演タレント要素生成
   * @return {promise}
   */
  createTalentBlock(id, name) {
    const d = $.Deferred();

    const formBlockElm = document.createElement("div");
    formBlockElm.classList.add("formBlock");
    formBlockElm.classList.add("formBlock--talent");
    formBlockElm.classList.add("js-add-talent-parent");

    const inputHiddenElm = document.createElement("input");
    inputHiddenElm.type = "hidden";
    inputHiddenElm.name = "persons[_ids][]";
    inputHiddenElm.value = id;
    formBlockElm.appendChild(inputHiddenElm);

    const inputSortElm = document.createElement("input");
    inputSortElm.type = "hidden";
    inputSortElm.value = this.sort++;
    inputSortElm.name = "historiesPersons[" + id + "][sort]";
    formBlockElm.appendChild(inputSortElm);

    const headerElm = document.createElement("div");
    headerElm.classList.add("formBlock__header");
    headerElm.innerText = "出演タレント";
    formBlockElm.appendChild(headerElm);

    const bodyElm = document.createElement("div");
    bodyElm.classList.add("formBlock__body");

    const talentElm = document.createElement("div");
    talentElm.classList.add("formBlock__talent");

    const row1Elm = document.createElement("div");
    row1Elm.classList.add("formBlock__talentRow");

    const linkElm = document.createElement("a");
    linkElm.href = "/persons/view/" + id;
    linkElm.target = "_blank";

    const row1DivElm = document.createElement("div");
    row1DivElm.innerText = name;
    linkElm.appendChild(row1DivElm);
    row1Elm.appendChild(linkElm);

    const row1SpanElm = document.createElement("span");
    row1SpanElm.innerText = "ギャランティ";
    row1Elm.appendChild(row1SpanElm);

    const row1InputElm = document.createElement("input");
    row1InputElm.type = "text";
    row1InputElm.name = "historiesPersons[" + id + "][guarantee]";
    row1InputElm.value = "";
    row1Elm.appendChild(row1InputElm);
    talentElm.appendChild(row1Elm);

    const row2Elm = document.createElement("div");
    row2Elm.classList.add("formBlock__talentRow");

    const row2DivElm = document.createElement("div");
    row2DivElm.innerText = "出演メモ";
    row2Elm.appendChild(row2DivElm);

    const row2Inputelm = document.createElement("input");
    row2Inputelm.type = "text";
    row2Inputelm.name = "historiesPersons[" + id + "][note]";
    row2Inputelm.value = "";
    row2Elm.appendChild(row2Inputelm);
    talentElm.appendChild(row2Elm);

    bodyElm.appendChild(talentElm);

    const controlElm = document.createElement("div");
    controlElm.classList.add("formBlock__talentControl");

    const deleteElm = document.createElement("button");
    deleteElm.type = "button";
    deleteElm.classList.add("button");
    deleteElm.classList.add("js-add-talent-delete-parent");
    deleteElm.innerText = "削除";
    bodyElm.appendChild(deleteElm);

    formBlockElm.appendChild(bodyElm);

    const parentElm = this.container.parentNode;
    parentElm.insertBefore(formBlockElm, this.container);

    d.resolve();
    return d.promise();
  }
}
