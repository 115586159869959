export default class Form {
  constructor() {
    this.lockFlg = false;
    this.init();
  }

  init() {
    this.bindEvents();
  }

  /**
   * イベント設定
   * @return {undefined}
   */
  bindEvents() {
    // submitボタン 連続クリック無効化
    const formElm = document.getElementsByTagName('form');
    if (formElm.length) {
      Array.prototype.forEach.call(formElm, elm => {
        elm.addEventListener('submit', e => {
          if (this.lockFlg) {
            e.preventDefault();
            return false;
          }
          this.lockFlg = true;
          setTimeout(() => {
            this.lockFlg = false;
          }, 3000);
        });
      });
    }

    // ラジオボタン
    const changeRadio = () => {
      const radioElms = document.getElementsByClassName("js-form-radio");
      if (radioElms.length) {
        Array.prototype.forEach.call(radioElms, elm => {
          const label = elm.closest("label");
          if (label) {
            if (elm.checked) {
              label.classList.add("-check");
            } else {
              label.classList.remove("-check");
            }
          }
        });
      }
    };
    changeRadio();
    $(document).on("change", ".js-form-radio", e => {
      changeRadio();
    });

    // チェックボックス
    const changeCheckbox = () => {
      const checkboxElms = document.getElementsByClassName("js-form-checkbox");
      if (checkboxElms.length) {
        Array.prototype.forEach.call(checkboxElms, elm => {
          const label = elm.closest("label");
          if (label) {
            if (elm.checked) {
              label.classList.add("-check");
            } else {
              label.classList.remove("-check");
            }
          }
        });
      }
    };
    changeCheckbox();
    $(document).on("change", ".js-form-checkbox", e => {
      changeCheckbox();
    });
  }
}
